<template>
  <div class="" style="width: 100%;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn text class="mx-2" @click="()=>{this.$router.go(-1)}">
            <v-icon dark>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="d-flex flex-column">
            <h1>Customer: {{this.cus.name}}</h1>
            <p>Created At: {{ utils.formatDate(this.cus.createdAt, 'withTime') }}</p>
            <p>Created By: {{ lookupUsername(this.cus.createdBy) }}</p>
          </span>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loader"
            style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="success" v-if="isEditable" :loading="updateCustomerLoading" @click="updateCustomer">Save</v-btn>
          <v-btn class="ml-2" v-if="isAllowed('customer', 'u')" @click="isEditable = !isEditable" color='info'>
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit Customer Information</span>
          </v-btn>
          <v-btn v-if="isEditable && isAllowed('customer', 'd')" class="ml-2" color="error" @click="confirmDeleteDialog.isOpen = true">Delete</v-btn>
        </v-col>
      </v-row>
      <span v-if="isEditable">
        <v-form :disabled="!isEditable">
          <v-row>
            <v-col>
              <v-text-field
                  label="Name"
                  outlined
                  v-model="cus.name"
              />
              <v-text-field
                  label="Phone"
                  outlined
                  v-model="cus.phone"
              />
              <v-text-field
                  label="Email"
                  outlined
                  v-model="cus.email"
              />
            </v-col>
            <v-col>
              <span v-if="this.getGlobalValue('VEC_INCLUDES_FULL_DOB') === true">
                <h3>Date Of Birth</h3>
                <v-date-picker label="Date of Birth" v-model="cus.dateOfBirth"/>
              </span>
              <span v-else>
                <h3>Date Of Birth</h3>
                <v-date-picker label="Date of Birth" v-model="cus.birthday"/>
              </span>
              <!-- <v-text-field
                  label="Contact Name"
                  outlined
                  v-model="cus.contact.name"
              />
              <v-text-field
                  label="Contact Phone"
                  outlined
                  v-model="cus.contact.phone"
              /> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
            <span class="d-flex flex-row align-center mb-2">
              <h2>Addresses</h2>
              <v-btn v-if="isEditable" @click="saveCustomAddressDialog=true" fab small class="ml-2" color="info"><v-icon>mdi-plus</v-icon></v-btn>
            </span>
              <v-card v-for="(addr, i) in cus.addresses" class="d-flex flex-column" outlined :key="i">
                <v-card-title class="d-flex flex-row justify-space-between">
                  <span>{{addr.name}}</span>
                  <v-btn v-if="isAllowed('customer', 'u')" fab x-small class="error" @click="deleteAddress(i)"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="d-flex flex-column">
                <span class="d-flex flex-row">
                  <v-text-field label="Address Line 1" v-model="addr.line1" outlined dense class="mx-2"/>
                  <v-text-field label="Address Line 2" v-model="addr.line2" outlined dense class="mx-2"/>
                </span>
                  <span class="d-flex flex-row">
                  <v-text-field label="City" v-model="addr.city" outlined dense class="mx-2"/>
                  <v-text-field label="Country" v-model="addr.country" outlined dense class="mx-2"/>
                </span>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </span>
      <v-row v-if="!this.loader">
        <v-col cols="4">
          <h2>Account Summary</h2>
          <v-btn class="my-2" v-if="isAllowed('customer', 'd')" @click="openCustomerBalanceReportDialog" color='info' :loading="customerBalanceReportDialog.isLoading">Generate Customer Balance</v-btn>
          <span class="d-flex flex-row">
            <span class="ml-3 d-flex flex-column justify-space-between">
              <span v-if="summary.totalPurchases">Total Purchases: {{utils.formatCurrency(summary.totalPurchases)}}</span>
              <span v-if="summary.totalPayments">Total Payments: {{utils.formatCurrency(summary.totalPayments)}}</span>
              <span v-if="summary.balance">Total Balance: {{utils.formatCurrency(summary.balance)}}</span>
            </span>
          </span>
        </v-col>
        <v-col v-if="getGlobalValue('VEC_VARIONCUSTOM_CUSTOMER_HOSTING_DETAILS')==='true'" cols="8">
          <span class="d-flex flex-row">
            <h2>Domain and Hosting Details</h2>
            <v-btn class="info ml-2" small @click="saveDomainHostingDetails" :loading="domainHostingDetailsLoader">
              Save
            </v-btn>
          </span>
          <v-textarea outlined v-model="cus.metadata.domainHostingDetails"></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-text>
              <h3>Orders</h3>
              <v-data-table
                  :headers="purchaseTable.headers"
                  :items="purchaseTable.items"
                  :options.sync="orderOptions"
                  :server-items-length="purchaseTable.pagination.dataCount"
                  :loading="purchaseTable.pagination.loading"
                  class="elevation-0"
                  :footer-props="{
                      itemsPerPageOptions: purchaseTable.pagination.pageSizeOptions
                    }"
                  @click:row="purchaseTableRowClick"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span v-if="item.createdAt">{{utils.formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <span v-if="item.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                  <span v-if="item.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                  <span v-if="item.status===1" class="v-btn success pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                </template>
                <template v-slot:item.createdBy="{ item }">
                  <span>{{lookupUsername(item.createdBy) }}</span>
                </template>
                <template v-slot:item.metadata.grandTotal="{ item }">
                  <span>{{utils.formatCurrency(item.metadata.grandTotal) }}</span>
                </template>
                <template v-slot:item.balance="{ item }">
                  <span>{{utils.formatCurrency(item.balance) }}</span>
                </template>
                <template v-slot:footer class="d-flex flex-row">
                  <v-text-field label="Go To Page" :min="1" :max="parseInt(purchaseTable.pagination.dataCount/orderOptions.itemsPerPage)+(purchaseTable.pagination.dataCount%orderOptions.itemsPerPage!==0?1:0)" type="number" dense outlined v-model="orderOptions.page"/>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined>
            <v-card-text>
              <h3>Returns</h3>
              <v-data-table
                  :headers="returnTable.headers"
                  :items="returnTable.items"
                  :options.sync="returnOptions"
                  :server-items-length="returnTable.pagination.dataCount"
                  :loading="returnTable.pagination.loading"
                  class="elevation-0"
                  :footer-props="{
                      itemsPerPageOptions: returnTable.pagination.pageSizeOptions
                    }"
                  @click:row="returnTableRowClick"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span v-if="item.createdAt">{{utils.formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <span v-if="item.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                  <span v-if="item.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                  <span v-if="item.status===1" class="v-btn success pa-1" style="font-size: 10px">{{parseOrderStatus(item.status)}}</span>
                </template>
                <template v-slot:item.createdBy="{ item }">
                  <span>{{lookupUsername(item.createdBy) }}</span>
                </template>
                <template v-slot:item.totalValue="{ item }">
                  <span>{{utils.formatCurrency(item.totalValue) }}</span>
                </template>
                <template v-slot:footer class="d-flex flex-row">
                  <v-text-field label="Go To Page" :min="1" :max="parseInt(returnTable.pagination.dataCount/returnOptions.itemsPerPage)+(returnTable.pagination.dataCount%returnOptions.itemsPerPage!==0?1:0)" type="number" dense outlined v-model="returnOptions.page"/>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined>
            <v-card-text>
              <h3>Payments</h3>
              <v-data-table
                  :headers="paymentTable.headers"
                  :items="paymentTable.items"
                  :options.sync="paymentOptions"
                  :server-items-length="paymentTable.pagination.dataCount"
                  :loading="paymentTable.pagination.loading"
                  class="elevation-0"
                  :footer-props="{
                      itemsPerPageOptions: paymentTable.pagination.pageSizeOptions
                    }"
                  @click:row="paymentTableRowClick"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span v-if="item.createdAt">{{utils.formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.receivedBy="{ item }">
                  <span>{{lookupUsername(item.receivedBy) }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>{{utils.formatCurrency(item.amount) }}</span>
                </template>
                <template v-slot:footer class="d-flex flex-row">
                  <v-text-field label="Go To Page" :min="1" :max="parseInt(paymentTable.pagination.dataCount/paymentOptions.itemsPerPage)+(paymentTable.pagination.dataCount%paymentOptions.itemsPerPage!==0?1:0)" type="number" dense outlined v-model="paymentOptions.page"/>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined>
            <v-card-text>
              <span class="d-flex flex-row">
                <h3>Credit Notes</h3>
                <v-btn @click="openCreateCreditNoteDialog" fab x-small class="ml-2" color="info"><v-icon>mdi-plus</v-icon></v-btn>

              </span>
              <v-data-table
                  :headers="creditNoteTable.headers"
                  :items="creditNoteTable.items"
                  :options.sync="creditNoteOptions"
                  :server-items-length="creditNoteTable.pagination.dataCount"
                  :loading="creditNoteTable.pagination.loading"
                  class="elevation-0"
                  :footer-props="{
                      itemsPerPageOptions: creditNoteTable.pagination.pageSizeOptions
                    }"
                  @click:row="creditNoteTableRowClick"
              >
                <template v-slot:item.createdAt="{ item }">
                  <span v-if="item.createdAt">{{utils.formatDate(item.createdAt) }}</span>
                </template>
                <template v-slot:item.status="{ item }">
                  <span v-if="item.status===-1" class="v-btn warning pa-1" style="font-size: 10px">{{parseCNStatus(item.status)}}</span>
                  <span v-if="item.status===0" class="v-btn orange pa-1" style="font-size: 10px">{{parseCNStatus(item.status)}}</span>
                  <span v-if="item.status===1" class="v-btn info pa-1" style="font-size: 10px">{{parseCNStatus(item.status)}}</span>
                  <span v-if="item.status===2" class="v-btn success pa-1" style="font-size: 10px">{{parseCNStatus(item.status)}}</span>
                </template>
                <template v-slot:item.issuedBy="{ item }">
                  <span>{{lookupUsername(item.issuedBy) }}</span>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span>{{utils.formatCurrency(item.amount) }}</span>
                </template>
                <template v-slot:footer class="d-flex flex-row">
                  <v-text-field label="Go To Page" :min="1" :max="parseInt(creditNoteTable.pagination.dataCount/creditNoteOptions.itemsPerPage)+(creditNoteTable.pagination.dataCount%creditNoteOptions.itemsPerPage!==0?1:0)" type="number" dense outlined v-model="creditNoteOptions.page"/>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog scrollable v-model="createCreditNoteDialog.isOpen" width="500">
      <v-card>
        <v-card-title>Create Credit Note</v-card-title>
        <v-card-text>
          <v-text-field type="number" min="0" dense outlined label="Credit Note Amount" v-model="createCreditNoteDialog.data.amount"/>
          <v-textarea class="mt-4" dense name="Reason" label="Reason" v-model="createCreditNoteDialog.data.reason" outlined ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCreateCreditNoteDialog">
            Cancel
          </v-btn>
          <confirmedActionButton
              class="mx-2" 
              color="success"   
              buttonText="Create"
              requireUsername
              requirePassword
              :loading="createCreditNoteDialog.isLoading"
              @cb="createCreditNote"
              fabIcon="mdi-close"
              :disabled="!createCreditNoteDialog.data || !createCreditNoteDialog.data.amount || !createCreditNoteDialog.data.reason" 
              :dialogText="'This will create a CN for the customer. Your account will be linked to this transaction. Are you sure you wish to proceed?'"
            />
          <!-- <v-btn :disabled="!createCreditNoteDialog.data || !createCreditNoteDialog.data.amount || !createCreditNoteDialog.data.reason" class="success" :loading="createCreditNoteDialog.isLoading" @click="createCreditNote">
            Create
          </v-btn> -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog 
      v-model="customerBalanceReportDialog.isOpen"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"  
    >
      <v-card>
        <v-card-title class=" d-flex flex-column text-h5">
          <span>Customer Balance to Date</span>
          <span style="font-size: 15px;">Generated at: {{ utils.formatDate(new Date(), 'withTime') }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Invoice #</th>
                      <th>Charges</th>
                      <th>Payment Type</th>
                      <th>Payment</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(row, index) in customerBalanceReportDialog.data" :key="index">
                        <td v-if="row.xtype =='INVOICE'">{{ utils.formatDate(row.sealedAt) }}</td>
                        <td v-if="row.xtype =='INVOICE'">{{ row.id }}</td>
                        <td v-if="row.xtype =='INVOICE'">{{ utils.formatCurrency(row.metadata.grandTotal) }}</td>
                        <td v-if="row.xtype =='INVOICE'"></td>
                        <td v-if="row.xtype =='INVOICE'"></td>
                        <td v-if="row.xtype =='INVOICE'">{{ utils.formatCurrency(row.balance || 0) }}</td>
                        <td v-if="row.xtype =='RECEIPT'">{{ utils.formatDate(row.createdAt) }}</td>
                        <td v-if="row.xtype =='RECEIPT'">{{ row.orderId }} <span style="font-size:12px;">(TRANS#{{ row.id }})</span></td>
                        <td v-if="row.xtype =='RECEIPT'"></td>
                        <td v-if="row.xtype =='RECEIPT'">by {{ lookupPaymentType(row.paymentType) }}</td>
                        <td v-if="row.xtype =='RECEIPT'">{{ utils.formatCurrency(row.amount) }}</td>
                        <td v-if="row.xtype =='RECEIPT'">{{ utils.formatCurrency(row.balance || 0) }}</td>
                    </tr>  
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCustomerBalanceReportDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="saveCustomAddressDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Save Address
        </v-card-title>
        <v-card-text>
          <v-text-field type="text" dense outlined label="Name of Address" v-model="newAddress.name"/>
          <span class="d-flex flex-row">
            <v-text-field label="Address Line 1" v-model="newAddress.line1" outlined dense class="mx-2"/>
            <v-text-field label="Address Line 2" v-model="newAddress.line2" outlined dense class="mx-2"/>
          </span>
          <span class="d-flex flex-row">
            <v-text-field label="City" v-model="newAddress.city" outlined dense class="mx-2"/>
            <v-text-field label="Country" v-model="newAddress.country" outlined dense class="mx-2"/>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCustomAddressDialog">
            Cancel
          </v-btn>
          <v-btn :disabled="!newAddress.name" class="success" text @click="saveCustomAddress">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewPaymentDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          View Payment
        </v-card-title>
        <v-card-text>
          <p>Order #: <router-link :to="'/orders/view/'+viewPaymentDialog.paymentToDisplay.orderId">{{viewPaymentDialog.paymentToDisplay.orderId}}</router-link></p>
          <p>Date Received: {{utils.formatDate(viewPaymentDialog.paymentToDisplay.createdAt)}}</p>
          <p>Received By: {{lookupUsername(viewPaymentDialog.paymentToDisplay.receivedBy)}}</p>
          <p>Amount: {{utils.formatCurrency(viewPaymentDialog.paymentToDisplay.amount)}}</p>
          <p>Payment Type: {{lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)}}</p>
          <p v-if="lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)=='Cheque'">Cheque Number: {{viewPaymentDialog.paymentToDisplay.metadata.chequeNumber}}</p>
          <span v-if="lookupPaymentType(viewPaymentDialog.paymentToDisplay.paymentType)=='Credit Note'">
            <span>Credit Notes Used: </span>
            <span v-for="cn in viewPaymentDialog.paymentToDisplay.metadata.creditNotes" :key="cn.id">
              {{cn}}
            </span>
          </span>
          <p v-if="viewPaymentDialog.paymentToDisplay.cnMessage">{{viewPaymentDialog.paymentToDisplay.cnMessage}}</p>
        </v-card-text>
        <v-card-actions>
          <v-btn small text color="error" @click="closeViewPaymentDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="viewCNDialog.isOpen" max-width="490">
      <v-card>
        <v-card-title class="text-h5">
          View CN # {{viewCNDialog.cn.id}}
        </v-card-title>
        <v-card-text class="d-flex flex-column">
          <span>Customer: {{this.cus.name}}</span>
          <span>Date Issued: {{utils.formatDate(viewCNDialog.cn.createdAt)}}</span>
          <span>Issued By: {{lookupUsername(viewCNDialog.cn.issuedBy)}}</span>
          <span>Expires At: {{utils.formatDate(viewCNDialog.cn.expiresAt)}}</span>
          <span>Amount: {{utils.formatCurrency(viewCNDialog.cn.amount)}}</span>
          <span>Reason/Notes: {{viewCNDialog.cn.notes}}</span>
          <p v-if="viewCNDialog.cn.metadata&&viewCNDialog.cn.metadata.returnId">This CN was issued from Return # {{viewCNDialog.cn.metadata.returnId}}</p>
          <p v-if="viewCNDialog.cn.metadata&&viewCNDialog.cn.metadata.paymentId">This CN was automatically issued from the balance on a previously redeemed CN, with Transaction # {{viewCNDialog.cn.metadata.paymentId}}</p>
        </v-card-text>
        <v-card-actions>
          <span class="d-flex flex-column">
            <span class="mb-2" style="margin-top: -20px;">
              <!-- <authorizer
                  v-if="getGlobalValue('VEC_ALLOW_CN_CASH_REDEEM')==='true' && viewCNDialog.cn.status===1"
                  v-bind:buttontext="'Redeem For Cash'"
                  v-bind:size="'small'"
                  v-bind:fabicon="null"
                  v-bind:color="'warning'"
                  v-on:response="authAttempt($event,'cashRedeem')"
              /> -->
            </span>
            <span class="d-flex flex-row justify-space-between">
                <v-btn color="info" :loading="printCNLoader" text small @click="printCN(viewCNDialog.cn.id)">Print CN</v-btn>
                <confirmedActionButton
                  v-if="getGlobalValue('VEC_ALLOW_CN_CASH_REDEEM')==='true'"
                  class="mx-2" 
                  color="warning" 
                  small
                  text  
                  buttonText="Redeem For Cash"
                  requireUsername
                  requirePassword
                  :loading="viewCNDialog.redeemCashLoading"
                  @cb="redeemCNForCash"
                  fabIcon="mdi-close"
                  :disabled="viewCNDialog.cn.status != 1"
                  :dialogText="'Redeeming a CN for cash cannot be undone. Are you sure you wish to proceed?'"
                />
                <v-btn text color="error" small @click="closeViewCNDialog">Close</v-btn>
            </span>
          </span>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDeleteDialog.isOpen" width="500">
      <v-card class="d-flex flex-column align-center justify-center">
        <v-card-title class="text-h5">Confirm Delete Customer</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click="confirmDeleteDialog.isOpen = false">Close</v-btn>
          <v-btn color="success" :loading="confirmDeleteDialog.loading" @click="deleteCustomer">Confirm</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="printPreviewDialog.isOpen"
      width="11in"
      scrollable
    >
      <v-card v-if="printPreviewDialog.data">
        <v-card-title class="text-h5">Preview</v-card-title>
        <v-card-text>
          <div id="orderPreview" v-html="printPreviewDialog.data.job.htmlToPrint"></div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintPreviewDialog">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="confirmRedeemDialog.isOpen"
        width="600"
    >
      <v-card>
        <v-card-title>
          Confirm Register Bypass
        </v-card-title>
        <v-card-text>
          <span>You currently don't have a register opened at the moment. Are you sure you want to continue with redeeming for cash before opening a register?</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancelRedeemConfirm">Close</v-btn>
          <v-btn color="success" @click="confirmRedeem">Redeem For Cash Without a Register</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import {mapGetters, mapMutations} from "vuex";
  import confirmedActionButton from './../../components/confirmedActionButton.vue';
  import utils from '../../plugins/helpers'
  export default {
    components: {
      confirmedActionButton
    },
    data () {
      return {
        utils: utils,
        deleteDialog: false,
        deleteConfirmed: true,
        loader: true,
        printCNLoader: false,
        domainHostingDetailsLoader:false,
        customerBalanceReportDialog: {
          isOpen: false,
          isLoading: false,
          data: {}
        },
        createCreditNoteDialog: {
          isOpen: false,
          isLoading: false,
          data: {}
        },
        snackObj: {
          state: false,
          color: '',
          text: ''
        },

        max25chars: v => v.length <= 25 || 'Input too long!',
        editMode: false,
        cus: {
          contact: {},
          addresses: []
        },
        isEditable: false,
        saveCustomAddressDialog: false,
        newAddress: {},

        purchaseTable: {
          headers: [
            {text: 'Order #', align: 'start', value: 'id'},
            { text: 'Status', value: 'status' },
            { text: 'Date Created', value: 'createdAt' },
            { text: 'CSR', sortable: false, value: 'createdBy' },
            { text: 'Grand Total', value: 'metadata.grandTotal' },
            { text: 'Balance', value: 'balance' },
          ],
          items: [],
          pagination: {
            loading: false,
            dataCount: 0,
            pageSizeOptions: [5, 10, 15, 30, 50, -1]
          }
        },
        orderOptions: {
          search: "",
          sortBy: ['id'],
          sortDesc: [false],
          page: 1,
          itemsPerPage: 10
        },

        returnTable: {
          headers: [
            {text: 'Return #', align: 'start', value: 'id'},
            { text: 'Status', value: 'status' },
            { text: 'Date Created', value: 'createdAt' },
            { text: 'CSR', sortable: false, value: 'createdBy' },
            { text: 'CN', value: 'cnId' },
            { text: 'Grand Total', value: 'totalValue' },
          ],
          items: [],
          pagination: {
            loading: false,
            dataCount: 0,
            pageSizeOptions: [5, 10, 15, 30, 50, -1]
          }
        },
        returnOptions: {
          search: "",
          sortBy: ['id'],
          sortDesc: [false],
          page: 1,
          itemsPerPage: 10
        },

        paymentTable: {
          headers: [
            { text: 'Order Ref # ', value: 'orderId' },
            {text: 'Date', align: 'start', value: 'createdAt'},
            { text: 'CSR', sortable: false, value: 'receivedBy' },
            { text: 'Amount', value: 'amount' },
          ],
          items: [],
          pagination: {
            loading: false,
            dataCount: 0,
            pageSizeOptions: [5, 10, 15, 30, 50, -1]
          }
        },
        paymentOptions: {
          search: "",
          sortBy: ['id'],
          sortDesc: [false],
          page: 1,
          itemsPerPage: 10
        },

        creditNoteTable: {
          headers: [
            {text: 'CN #', align: 'start', value: 'id'},
            { text: 'Status', value: 'status' },
            {text: 'Date', align: 'start', value: 'createdAt'},
            { text: 'CSR', sortable: false, value: 'issuedBy' },
            { text: 'Amount', value: 'amount' },
            {text: 'Return #', align: 'start', value: 'metadata.returnId'},
          ],
          items: [],
          pagination: {
            loading: false,
            dataCount: 0,
            pageSizeOptions: [5, 10, 15, 30, 50, -1]
          }
        },
        creditNoteOptions: {
          search: "",
          sortBy: ['id'],
          sortDesc: [false],
          page: 1,
          itemsPerPage: 10
        },

        summary: {},

        viewPaymentDialog: {
          isOpen: false,
          paymentToDisplay: {},
        },
        viewCNDialog: {
          isOpen: false,
          redeemCashLoading: false,
          cn: {},
        },
        printDialog: {
          isOpen: false,
          quantity: 1,
          jobType: '',
          deliveryNote: '',
          printer: '',
        },
        printPreviewDialog: {
          isOpen: false,
          data: '',
          loading: false,
        },
        confirmDeleteDialog: {
          isOpen: false,
          loading: false,
        },
        updateCustomerLoading: false,
        balanceReport: {},
        balanceReportAlt: [],

        confirmRedeemLoading: false,
        confirmRedeemCredentials: null,
        confirmRedeemDialog: {
          isOpen: false,
        },
      }
    },
    async mounted(){
        try {
          let res = await axios.get(`${this.getEndpoint}/api/customers/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error 
          this.cus = res.data.data
          this.purchaseTable.items = []
          this.returnTable.items = []
          this.creditNoteTable.items = []
          this.paymentTable.items = []

          await this.getOrderData();
          await this.getReturnData();
          await this.getPaymentData();
          await this.getCreditNoteData();
          await this.getSummaryData();

          console.log(this.cus.metadata)

        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
        }
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed', 'lookupUsername', 'lookupPaymentType','getGlobalValue'])
    },
    watch: {
      orderOptions: {
        async handler () {
          await this.getOrderData();
        },
        deep: true,
      },
      returnOptions: {
        async handler () {
          await this.getReturnData();
        },
        deep: true,
      },
      paymentOptions: {
        async handler () {
          await this.getPaymentData();
        },
        deep: true,
      },
      creditNoteOptions: {
        async handler () {
          await this.getCreditNoteData();
        },
        deep: true,
      },
    },
    methods: {
      ...mapMutations(['updateCurrentRegister']),
      async openCustomerBalanceReportDialog(){
        try{
          this.customerBalanceReportDialog.isLoading = true

          let res = await axios.get(`${this.getEndpoint}/api/customers/balanceReport/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          let temp = res.data.data

          let bbd = 0

          for(let i of temp){

            i.xtype = "INVOICE"
            i.totalPayments = i.Transactions.reduce((acc,x)=>acc+parseFloat(x.amount),0)
            bbd += parseFloat(i.metadata.grandTotal)
            i.balance = bbd
            this.balanceReportAlt.push(i)

            for(let j of i.Transactions){
              j.xtype = "RECEIPT"
              bbd -= parseFloat(j.amount)
              j.balance = bbd
              this.balanceReportAlt.push(j)
            }

          }
        
          this.customerBalanceReportDialog.data = temp
          this.customerBalanceReportDialog.isOpen = true
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.customerBalanceReportDialog.isLoading = false
        }
      },
      async printCN(id){
        try {
          this.printCNLoader = true
          let x = await axios.post(`${this.getEndpoint}/api/custom/print/creditnote/${id}`)
          if(x.data.error) throw x.data.error
          let printWindow = open("","Printing")
          printWindow.document.write("")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.document.write("")
            printWindow.close()
          },500)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.printCNLoader = false
        }
      },
      cancelRedeemConfirm(){
        this.paymentToConfirmType = "";
        this.confirmRedeemDialog.isOpen = false;
      },
      confirmRedeem(){
        this.confirmRedeemDialog.bypass = true;
        this.redeemCNForCashConfirmed(this.confirmRedeemCredentials)

        this.cancelRedeemConfirm()
      },
      async redeemCNForCash(type){
        try{
          this.confirmRedeemLoading = true;

          if(this.getGlobalValue('registerModuleEnabled')!=='true'){
            await this.redeemCNForCashConfirmed(type);
            return
          }

          this.confirmRedeemCredentials = type;

          let res = await axios.get(`${this.getEndpoint}/api/registers/checkPaymentBypass`)
          if(res.data.error) throw res.data.error
          if(!res.data.data.prompt){
            await this.redeemCNForCashConfirmed(type)
            return;
          }
          this.confirmRedeemDialog.isOpen = true;
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.confirmRedeemLoading = false
        }
      },
      async redeemCNForCashConfirmed(obj){
        try{
          this.viewCNDialog.redeemCashLoading = true;

          let res = await axios.post(`${this.getEndpoint}/api/creditNotes/cashRedeem/${this.viewCNDialog.cn.id}`, obj)
          if(res.data.error) throw res.data.error;
          this.snack("✅ Success.")
          this.viewCNDialog.cn.status = 2;
          this.$forceUpdate();
          this.closeViewCNDialog();
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
        finally{
          this.viewCNDialog.redeemCashLoading = false;
        }
      },
      closeCustomerBalanceReportDialog(){
        this.customerBalanceReportDialog.isLoading = false
        this.customerBalanceReportDialog.isOpen = false
        this.customerBalanceReportDialog.data = {}
        
      },
      openCreateCreditNoteDialog(){
        this.createCreditNoteDialog.data = {}
        this.createCreditNoteDialog.isOpen = true
      },
      closeCreateCreditNoteDialog(){
        this.createCreditNoteDialog.isLoading = false
        this.createCreditNoteDialog.isOpen = false
        this.createCreditNoteDialog.data = {}
        
      },
      async createCreditNote(creds){
        try{
          this.createCreditNoteDialog.isLoading = true
          
          let obj = this.createCreditNoteDialog.data
          obj.customerId = this.$route.params.id
          obj.username = creds.username
          obj.password = creds.password

          let res = await axios.post(`${this.getEndpoint}/api/creditNotes/`, obj)
          if(res.data.error) throw res.data.error
          console.log(res.data.data)
          this.creditNoteTable.items.unshift(res.data.data)
          this.snack("Credit note created!", 'success');
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.closeCreateCreditNoteDialog()
        }
      },
      async getSummaryData(){
        try{
          let res = await axios.get(`${this.getEndpoint}/api/customers/summaryData/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error
          this.summary = res.data.data
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async saveDomainHostingDetails(){
        try{
          this.domainHostingDetailsLoader = true
          let res = await axios.put(`${this.getEndpoint}/api/customers/domainHostingDetails/${this.$route.params.id}`, {data: this.cus.metadata.domainHostingDetails})
          if(res.data.error) throw res.data.error
          this.snack("Details Saved", 'success');
          this.domainHostingDetailsLoader = false
        }
        catch (error) {
          this.domainHostingDetailsLoader = false
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      async getOrderData(){
        try{
          this.purchaseTable.pagination.loading = true;

          if(!this.orderOptions.page || this.orderOptions.page<=0) return;

          let paginationData = {
            page: this.orderOptions.page,
            limit: this.orderOptions.itemsPerPage,
            sort: (this.orderOptions.sortBy.length>0)?this.orderOptions.sortBy[0]:"id",
            order: this.orderOptions.sortDesc[0],
          }

          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/customers/orderData/${this.$route.params.id}?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.purchaseTable.items = res.data.data

          //used for pagination
          this.purchaseTable.pagination.dataCount = res.data.total
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.purchaseTable.pagination.loading = false;
        }
      },
      async getReturnData(){
        try{
          this.returnTable.pagination.loading = true;

          if(!this.returnOptions.page || this.returnOptions.page<=0) return;

          let paginationData = {
            page: this.returnOptions.page,
            limit: this.returnOptions.itemsPerPage,
            sort: (this.returnOptions.sortBy.length>0)?this.returnOptions.sortBy[0]:"id",
            order: this.returnOptions.sortDesc[0],
          }

          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/customers/returnData/${this.$route.params.id}?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.returnTable.items = res.data.data

          //used for pagination
          this.returnTable.pagination.dataCount = res.data.total
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.returnTable.pagination.loading = false;
        }
      },
      async getPaymentData(){
        try{
          this.paymentTable.pagination.loading = true;

          if(!this.paymentOptions.page || this.paymentOptions.page<=0) return;

          let paginationData = {
            page: this.paymentOptions.page,
            limit: this.paymentOptions.itemsPerPage,
            sort: (this.paymentOptions.sortBy.length>0)?this.paymentOptions.sortBy[0]:"id",
            order: this.paymentOptions.sortDesc[0],
          }

          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/customers/paymentData/${this.$route.params.id}?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.paymentTable.items = res.data.data

          //used for pagination
          this.paymentTable.pagination.dataCount = res.data.total
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.paymentTable.pagination.loading = false;
        }
      },
      async getCreditNoteData(){
        try{
          this.creditNoteTable.pagination.loading = true;

          if(!this.creditNoteOptions.page || this.creditNoteOptions.page<=0) return;

          let paginationData = {
            page: this.creditNoteOptions.page,
            limit: this.creditNoteOptions.itemsPerPage,
            sort: (this.creditNoteOptions.sortBy.length>0)?this.creditNoteOptions.sortBy[0]:"id",
            order: this.creditNoteOptions.sortDesc[0],
          }

          let uriFields = Object.keys(paginationData).map(x => {
            return x + "=" + (paginationData[x]!==undefined?encodeURIComponent(paginationData[x]):'')
          }).join("&");
          //used for pagination end

          let res = await axios.get(`${this.getEndpoint}/api/customers/creditNoteData/${this.$route.params.id}?${uriFields}`)
          if(res.data.error) throw res.data.error
          this.creditNoteTable.items = res.data.data

          //used for pagination
          this.creditNoteTable.pagination.dataCount = res.data.total
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
        finally {
          this.creditNoteTable.pagination.loading = false;
        }
      },
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async updateCustomer(){
        try {
          this.loader = true;
          this.updateCustomerLoading = true

          let obj = {
            name: this.cus.name,
            phone: this.cus.phone,
            email: this.cus.email,
            addresses: this.cus.addresses,
          }

          let res = await axios.put(`${this.getEndpoint}/api/customers/${this.$route.params.id}`, obj)
          if(res.data.error) throw res.data.error

          if(res.data.success){
            this.snack("Customer Updated 🎉", "success");
            this.isEditable = false;
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.loader = false;
          this.updateCustomerLoading = false
        }
      },
      async deleteCustomer(){
        try {
          this.confirmDeleteDialog.loading = true
          let res = await axios.delete(`${this.getEndpoint}/api/customers/${this.$route.params.id}`)
          if(res.data.error) throw res.data.error

          this.snack("Customer Deleted 🎉", "success");
          await this.$router.go(-1)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally {
          this.confirmDeleteDialog.loading = false
        }
      },
      async saveCustomAddress(){
        try{
          let res = await axios.put(`${this.getEndpoint}/api/customers/addAddress/${this.$route.params.id}`, this.newAddress)
          if(res.data.error) throw res.data.error

          if(res.data.success) {
            this.snack("Address added successfully!", "success");
            this.cus.addresses.push(this.newAddress);
            this.closeCustomAddressDialog();
          }
        }
        catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        }
      },
      closeCustomAddressDialog(){
        this.newAddress = {};
        this.saveCustomAddressDialog = false;
      },
      async deleteAddress(i){
        try {
          this.loader = true
          let res = await axios.put(`${this.getEndpoint}/api/customers/removeAddress/${this.$route.params.id}/${i}`)
          if(res.data.error) throw res.data.error

          this.snack("Address Deleted 🎉", "success");
          this.cus.addresses.splice(i, 1);
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error.msg?.message || error, "error");
        } finally{
          this.loader=false;
        }
      },
      paymentTableRowClick(e){
        this.viewPaymentDialog.paymentToDisplay = e
        this.viewPaymentDialog.isOpen = true
        if(!this.$route.query) this.$router.replace({'query': null});
      },
      closeViewPaymentDialog(){
        this.viewPaymentDialog.isOpen = false
        this.viewPaymentDialog.paymentToDisplay = {}
      },
      creditNoteTableRowClick(e){
        this.viewCNDialog.cn = e
        this.viewCNDialog.isOpen = true
        if(!this.$route.query) this.$router.replace({'query': null});
      },
      closeViewCNDialog(){
        this.viewCNDialog.isOpen = false
        this.viewCNDialog.cn = {}
      },
      purchaseTableRowClick(row){
        this.$router.push({path: `/orders/view/${row.id}`})
      },
      returnTableRowClick(e){
        this.$router.push({path: `/returns/view/${e.id}`})
      },
      parseDeliveryStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Delivered"
      },
      parseCNStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Unredeemed"
        else if(id===2) return "Redeemed"
      },
      parseOrderStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
      },
      openCloudPrintDialog(){
        this.cloudPrintDialog.isOpen = true
      },
      async printDirect(type, metadata){
        try {
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/${type}/${metadata.id}`)
          if(x.data.error) throw x.data.error
          let printWindow = open("","Printing")
          printWindow.document.write(x.data.data.job.htmlToPrint)
          printWindow.setTimeout(()=>{
            printWindow.print()
            printWindow.close()
          },300)
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },      
      async printPreview(type, metadata){
        try {
          this.printPreviewDialog.loading = true
          let x = await axios.post(`${this.getEndpoint}/api/print/preview/${type}/${metadata.id}`)
          if(x.data.error) throw x.data.error
          this.printPreviewDialog.data = x.data.data
          this.printPreviewDialog.loading = false
          this.printPreviewDialog.isOpen = true
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      closePrintPreviewDialog(){
        this.printPreviewDialog.loading = false
          this.printPreviewDialog.data = ''
          this.printPreviewDialog.isOpen = false
      },
      closePrintDialog(){
        this.cloudPrintDialog = {
          isOpen: false,
          quantity: 1,
          jobType: '',
          deliveryNote: '',
          printer: ''
        }
      },
      async authAttempt(credentials, type){
        console.log(this.viewCNDialog)
        try {
          this.loader = true;
          if(!credentials) throw "No Credentials Supplied"
          switch(type){
            case "cashRedeem":
              await this.redeemCNForCash(credentials);
              break
            default:
              throw "No Type Supplied to Handler"
          }
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        } finally {
          this.loader = false;
        }
      }
    }
  }
</script>
